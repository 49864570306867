<template>
    <Select
            v-model="selected_account"
            :items="accounts"
            :label="$t('navbar.AccountChanger.choose_account')"
            background="cell"
            itemText="display_name"
    />
</template>

<script>
export default {
    name: "SelectAccount",
    components: {
        Select: () => import('@/components/fields/Select.vue')
    },
    computed: {
        selected_account: {
            get() {
                return this.$store.getters['auth/selected_account']
            },
            set(val){
                this.$store.commit('auth/CHANGE_SELECTED_ACCOUNT_VAL', val)
            }
        },
        accounts(){
            return this.$store.getters['auth/available_accounts']
        }
    }
}
</script>
